import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import "./HeroSection.css"
import QuoteFormModal from '../Forms/QuoteFormModal'

import BackgroundImage from 'gatsby-background-image'


const HeroSection = () => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(relativePath: { eq: "background.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.heroImage.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className="heroContainer"
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
           
           <div className="overlay">
             <div className="content-area">
             <h1>Sahara Interpretation And Translation Services</h1>
                <QuoteFormModal />
             </div>
            </div>
        </BackgroundImage>
      )
    }}
  />
)



export default HeroSection;


