import React from 'react'
import {RiVideoChatFill} from '@react-icons/all-files/ri/RiVideoChatFill'
import {FaPhone} from '@react-icons/all-files/fa/FaPhone'
import {IoIosPeople} from '@react-icons/all-files/io/IoIosPeople'
import {HiOutlineDocumentDuplicate} from '@react-icons/all-files/hi/HiOutlineDocumentDuplicate'
import {ServiceContainer, MainTitle, Card, Box, Content, CardIcon, Title, Text, BottonLink} from './ServiceElements'


const index = () => {
    return (
            <>
            <MainTitle>Services We Offer</MainTitle>
           <ServiceContainer>
           
               
               <Card>
                   <Box>
                     <Content>
                     <CardIcon><IoIosPeople /></CardIcon>
                     <Title>In person Interpretation</Title>
                           <Text>We provide in person interpretation service</Text>
                           <BottonLink to="/interpretation">Read more...</BottonLink>
                     </Content>
                   </Box>
               </Card>
               <Card>
                   <Box>
                     <Content>
                     <CardIcon><FaPhone /></CardIcon>
                     <Title>Over The Phone Interpretation</Title>
                           <Text>Pre-scheduled over the phone interpreting</Text>
                           <BottonLink to="/interpretation">Read more...</BottonLink>
                     </Content>
                   </Box>
               </Card>
               <Card>
                   <Box>
                     <Content>
                      <CardIcon><RiVideoChatFill /></CardIcon>
                     <Title>Video Conferencing</Title>
                           <Text>In this limited contact period we are in</Text>
                           <BottonLink to="/interpretation">Read more...</BottonLink>
                     </Content>
                   </Box>
               </Card>
               <Card>
                   <Box>
                     <Content>
                     <CardIcon><HiOutlineDocumentDuplicate /></CardIcon>
                     <Title>Document Translation</Title>
                           <Text>We have a well-trained and educated linguistic professionals. They are specialized in translating medical documents, Human resources</Text>
                           <BottonLink to="/translation">Read more...</BottonLink>
                     </Content>
                   </Box>
               </Card>
             
           </ServiceContainer>
     </>
    )
}

export default index
