import styled from 'styled-components'
import {Link} from 'gatsby'
import ServiceSection from '.'

export const ServiceContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 20px 0; 
    background: #ebf5fc;
    
`

export const MainTitle = styled.h1`
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #ebf5fc;
    margin: 0;
    padding: 0;
    font-family: "Ubuntu", sans-serif;
    font-size: 1.5em;
    color: #525354;
    padding-top: 50px;

`

export const Card = styled.div`
    position: relative;
    min-width: 20%;
    height: 440px;
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05);
                inset -5px -5px -5px rgba(255,255,255,0.5),
                5px 5px 5px rgba(0,0,0,0.5),
                -5px -5px -5px rgba(255,255,255,0.5);
    border-radius: 15px;
    margin: 30px;

    @media screen and (max-width: 960px) {
        width: 90%;
        height: 340px;
    }
`

export const Box = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: #ebf5fc;
    box-shadow: 0 10 20px rgba(0,0,0,0.1);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;

        &:hover {
            transform: translateY(-25px);
            box-shadow: 0 10px 40px rgba(0,0,0,0.2);
            background: linear-gradient(45deg, #465054)
        }
`

export const Content = styled.div`
    padding: 20px;
    text-align: center;
`

export const CardIcon = styled.div`
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 8em;
    color: rgba(0,0,0,0.02);
    transition: 0.5s
    pointer-events: none;

        &:hover {
            color: rgba(0,0,0,0.1);
        }
`

export const Title = styled.h3`
    font-size: 1.8em;
    color: #777;
    z-index: 1;
    transition: 0.5s;

        &:hover {
            color: #fff;
        }
`


export const Text = styled.p`
    font-size: 1em;
    font-weight: 300;
    color: #777;
    z-index: 1;
    transition: 0.5s;

        &:hover {
            color: #fff;
        }
`

export const BottonLink = styled(Link)`
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    background: #3da3bf;
    margin-top: 15px;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);

        &:hover {
            background: #ff568f;
        }
`


export default ServiceSection