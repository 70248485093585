import React from 'react'
import './about-us-section.css'

const AboutUsSection = () => {
    return (
        <div className="about-section">
            <h1>Sahara Interpretation And Translation(SITS)</h1>
            <p>
            SITS has been created provide the highest quality of ethical and professional interpreting services to meet the needs of our clients for hospitals, Insurance companies, Government agencies for the city, county, and state, for schools and court houses. We also provide document translation.
            SITS LLC intends to provide exceptional, personalized service, which will be the crucial factor in building and protecting the Business's brand within the community. It also intends to handle customer concerns and issues with a customer-oriented focus with the intent of providing timely resolution and preventing the loss of customers.  We currently offer Kunama, Tigrinya, Amharic, Somali, Oromo, Sudanese-Arabic, with more languages on the way.
            </p>
        </div>
    )
}

export default AboutUsSection


