import React from "react"
import "../components/mainIndexPage.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/HeroSection"
import ServiceSection from "../components/ServiceSection"
import AboutUsSection from "../components/AboutUsSection"

import Image from 'gatsby-image'

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" />
    <HeroSection />
    <ServiceSection />
    <AboutUsSection />
    <div className="language-container">
    {data.allContentfulLanguage.nodes.map(item => (
          <div key={item.slug} className="language-section">
            <div className="language-section"></div>
            <h1>{item.title}</h1>
            <Image className="language-image" fluid={item.flag.fluid} alt={item.title}></Image>

            <div>
            {item.languageList.map(language => (
              <div key={language.slug}>
                <h3 className="language-list">{language}</h3>
              </div>
            ))}
            </div>
          </div>
        ))}
    </div>

  </Layout>
)



export default IndexPage

export const query = graphql`
  {
    allContentfulLanguage {
      nodes {
        id
        title
        slug
        languageList
        flag {
          fluid(maxWidth: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
